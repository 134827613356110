import React, { useState } from "react";
import { FormProps } from "../../../../types/component-types/otherActivitiesProps";
import { Flex } from "antd";
import { useApiRequest } from "../../../../hooks/useApiRequests";
import useMessages from "../../../../hooks/useMessages";
import MediaCard from "../../../MediaCard/MediaCard";
import MediaEditModal from "../../../MediaEditModal/MediaEditModal";
import InfoItemsMediaCard from "src/components/SkiLessonsMediaCard/InfoItemsMediaCard";
import { ItemT, messageResT } from "src/api";
import { cloudinaryUpload } from "src/lib/cloudinaryUpload";

const FormSection: React.FC<FormProps> = ({
  header,
  data,
  updateService,
  getData,
}) => {
  const { title, subtitle, warningTitle, warning, items } = data;
  const [editForm, setEditForm] = useState<{
    title: string;
    subtitle: string;
    warningTitle: string;
    warning?: string;
  }>(data);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [itemEditValue, setItemEditValue] = useState<ItemT>();

  const [, makeUpdateRequest] = useApiRequest<messageResT>();
  const { error, success, contextHolder } = useMessages();

  const showEditModal = () => {
    setIsEditModalOpen(true);
    setEditForm(data);
  };

  const handleOkEdit = () => {
    if (editForm) {
      setLoading(true);
      try {
        makeUpdateRequest(
          updateService({
            title: editForm.title,
            subtitle: editForm.subtitle,
            warningTitle: editForm.warningTitle,
            warning: editForm.warning,
          }),
          (e) => {
            setLoading(false);
            setIsEditModalOpen(false);
            success(e.message);
            getData();
          }
        );
      } catch (err) {
        setLoading(false);
        error("Something went wrong");
      }
    }
  };

  const handleCancelEdit = () => {
    setIsEditModalOpen(false);
  };

  const handleFieldChange = (fieldName: string, value: string) => {
    setEditForm((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const paragraphs = subtitle.split("\n");
  const paragraphsWithBreaks = paragraphs.map((paragraph) => (
    <>
      <h4>{paragraph}</h4>
      <br />
    </>
  ));

  const handleItemEdit = (id: string) => {
    const filteredData = items.find((item) => item._id === id);

    setItemEditValue((prev) => {
      if (prev === filteredData) return undefined;

      return filteredData;
    });
  };

  const handleItemFieldChange = async (fieldName: string, value: any) => {
    let image: any;
    setLoading(true);
    if (value && fieldName === "image") {
      image = await cloudinaryUpload(value);
    }
    setItemEditValue(
      (prev) =>
        ({
          ...prev,
          [fieldName]: fieldName === "image" ? image : value,
        } as ItemT)
    );
    setLoading(false);
  };

  const onItemEditConfirm = () => {
    try {
      setLoading(true);
      makeUpdateRequest(
        updateService(
          {
            description: itemEditValue?.description,
            image: itemEditValue?.image,
          },
          itemEditValue?._id
        ),
        (e) => {
          success(e.message);
          setItemEditValue(undefined);
          getData();
          setLoading(false);
        }
      );
    } catch (err) {
      error("Something went wrong");
    }
  };

  return (
    <>
      {contextHolder}
      <section>
        <Flex vertical gap={24}>
          <Flex vertical gap={12}>
            <h2>{header}</h2>
            <MediaCard
              data={{
                _id: "",
                title: title,
                warningTitle: warningTitle,
                subtitle: paragraphsWithBreaks,
                warning: warning,
              }}
              onEdit={showEditModal}
            />
          </Flex>

          <InfoItemsMediaCard
            data={items}
            itemEditValue={itemEditValue}
            loading={loading}
            onEdit={handleItemEdit}
            handleFieldChange={handleItemFieldChange}
            handleUpdate={onItemEditConfirm}
          />
        </Flex>
        <MediaEditModal
          isMediaEditModalOpen={isEditModalOpen}
          handleOk={handleOkEdit}
          handleCancel={handleCancelEdit}
          handleFieldChange={handleFieldChange}
          mediaDetails={editForm}
          isLoading={loading}
          containsImage={false}
          useTextAreaForSubtitleEdit
        />
      </section>
    </>
  );
};
export default FormSection;
